import React from 'react'

import { graphql } from 'gatsby'

import { BannerPage } from '../components/BannerPage'
import { Container } from '../components/Container'
import { LinkCustom } from '../components/LinkCustom'
import { PeopleList } from '../components/PeopleList'
import { Seo } from '../components/Seo'

const Guests = ({ data }) => {
  const { guests } = data

  const title = 'Our Guests'
  const description =
    'Meet our beloved guests who shared their stories on World Web Stories. (The List below is ordered by date of publication)'
  return (
    <>
      <Seo title={title} description={description} pathname="guests/" isLanding />

      <BannerPage
        title={title}
        description={description}
        component={
          <>
            If you are interested, you can also{' '}
            <LinkCustom to="/be-a-guest/" className="underline">
              be a guest
            </LinkCustom>{' '}
            for the podcast!
          </>
        }
      />

      <Container className="pt-10">
        <PeopleList people={guests} />
      </Container>
    </>
  )
}

export default Guests

export const query = graphql`
  query AllGuestsQuery {
    guests: allAirtableGuests(
      sort: { fields: data___Release_Date_Episode, order: DESC }
      filter: { data: { Release_Date_Episode: { ne: null }, Episode: { ne: null } } }
    ) {
      nodes {
        data {
          Full_name
          Avatar___profile_image {
            thumbnails {
              large {
                url
              }
            }
          }
          Title
          Country {
            data {
              Name_country
            }
          }
          Personal_website___blog
          Code_country
          Twitter
          LinkedIn
          Instagram
          Github
          Facebook_profile
          Bio
          Url_episode__from_Episode_
        }
      }
    }
  }
`
