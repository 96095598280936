import React from 'react'

interface IBannerPageProps {
  description?: string
  title?: string
  component?: React.ReactNode
}

export const BannerPage: React.FC<IBannerPageProps> = ({ description, title, component }) => {
  return (
    <div className="bg-white">
      <section className="max-w-6xl mx-auto pt-44 px-2 sm:px-4 lg:px-8">
        <div className="sm:pb-10 md:pb-10">
          <div className="grid grid-cols-1 gap-y-10 md:grid-cols-4 md:gap-18">
            <div>
              <h1 className="text-4xl font-bold text-gray-900 md:text-5xl">{title && title}</h1>
            </div>
            <div className="sm:pl-40 col-span-3">
              {description && <div className="font-serif text-xl pb-10">{description}</div>}
              {component && <div>{component}</div>}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
